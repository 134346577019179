#outputForIcon {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px dotted white;
}

#outputFor4By3 {
    width: 400px;
    height: 300px;
    object-fit: cover;
    border: 3px dotted white;
    border-radius: 20px;
}

#outputFor1By1 {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border: 3px dotted white;
    border-radius: 20px;
}

#spinner {
    display: none;
    height: 50px;
}
