.overlay {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    background: rgba(0, 0, 0, 0.9);
}
