@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');

/*
Overriding existing DOM and class definitions.
*/

body {
    background-color: black;
    color: white;
    font-family: 'Poppins', sans-serif;
}

h1, h2 {
    letter-spacing: 5px;
}

input[type=email], input[type=password], input[type=tel], input[type=text], .amphere-input-text, .amphere-input-dropdown {
    height: 25px;
    background: black;
    color: white;
    border: 2px solid white;
    border-radius: 10px;
    padding: 20px;
}

input[type=email]:focus, input[type=password]:focus, input[type=tel]:focus, input[type=text]:focus, .amphere-input-text:focus, .amphere-input-dropdown:focus {
    background: #303030;
    color: white;
}

/* Submission buttons in forms. */
input[type=submit], .amphere-pill-button {
    display: block;
    width: 200px;
    height: 50px;
    background: white;
    color: black;
    text-align: center;
    /* TODO: Temporary fix. Prevents buttons from being multi-lined. */
    line-height: 40px;
    font-weight: bold;
    border: none;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
}

/*
Custom Amphere background colours.
*/

.bg-amphere-red {
    background-color: #A70000;
}

.bg-amphere-beige {
    background-color: #BDA888;
}

.bg-amphere-brown {
    background-color: #BD6222;
}

/*
Custom Amphere class definitions.
*/

.container-narrow {
    max-width: 720px;
}

/* See input[type=text]. */
.amphere-input-textarea {
    background: black;
    color: white;
    border: 2px solid white;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    resize: none;
}

/* See input[type=text]:focus. */
.amphere-input-textarea:focus {
    background: #303030;
    color: white;
}

.amphere-hollow-button {
    display: block;
    width: 100%;
    height: 50px;
    background: black;
    color: white;
    text-align: left;
    font-weight: bold;
    border: 2px solid white;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 15px;
}

.amphere-hollow-button:hover {
    background: white;
    color: black;
    -webkit-transition: background 0.25s linear, color 0.25s linear;
    -ms-transition: background 0.25s linear, color 0.25s linear;
    transition: background 0.25s linear, color 0.25s linear; /* Animation. */
}

/* Defines animations for objects that zoom upon hovering. */
.zoomable {
    cursor: pointer;
    transition: box-shadow 0.25s, transform 0.25s; /* Animation. */
}

/* Defines animations for objects that zoom upon hovering. */
.zoomable:hover {
    box-shadow: 0 0 25px rgba(30, 30, 30, 0.5);
    transform: scale(1.10);
}

.rounded-fixed-10 {
    border-radius: 10px;
}

.rounded-fixed-25 {
    border-radius: 25px;
}

/*
Custom Amphere font weights. Helper class that replace the original definition found in Bootstrap.
*/

.font-weight-normal {
    font-weight: 400;
}

.font-weight-light {
    font-weight: 200;
}

.font-weight-bold {
    font-weight: 700;
}
